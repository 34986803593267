import { createApp } from 'vue';
import { NavigationGuardNext, RouteLocationNormalized } from 'vue-router';
import ElementPlus from 'element-plus';
import { createPinia } from 'pinia';
import '@/style/element-variables.scss';
import '@/style/global.scss';
import locale from 'element-plus/lib/locale/lang/zh-cn';

// EUI 需在 ElementUI 之后引入
import EUI from '@aciga/e-ui-next';
import '@aciga/e-ui-next/dist/theme-chalk/css/index.css';

import { RegistrableApp } from '@/micro/interfaces';
import startQiankun, { setupQiankun } from '@/micro';
import getApps from '@/micro/apps';
import router, { routes } from '@/router';
import App from './App.vue';

import 'element-plus/es/components/base/style/index';
import 'element-plus/es/components/input/style/index';
import 'element-plus/es/components/icon/style/index';

const pinia = createPinia();

function mountApp() {
  const app = createApp(App);
  app.use(ElementPlus, { locale, size: 'small', zIndex: 3000 });
  app.use(EUI);
  app.use(pinia);
  app.use(router);
  app.mount('#main-app');
  return app;
}

function setup() {
  mountApp();
}

setup();
